import { Outlet } from "react-router-dom";
import Footer from "../footer";
import WithSubnavigation from "../navBar";


function Layout() {
    return (
        <>
            <WithSubnavigation />
            <Outlet />
            <Footer />
        </>



    );
}

export default Layout;