import { Box, Container, Flex, Heading, Image, SimpleGrid, Stack, StackDivider, Text, useColorModeValue } from "@chakra-ui/react"
import { Feature } from "./contents/contentOne"
import Proyectos from '../components/utils/proyectosData.js'

const RowText = (props) => {
    const colors = [['yellow.100', 'yellow.900'], ['green.100', 'green.900'], ['purple.100', 'purple.900']]
    const { proyecto, fecha, desc, claims } = props
    return <Stack spacing={4} >
        <Text
            textTransform={'uppercase'}
            color={'blue.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}>
            {fecha}
        </Text>
        <Heading>{proyecto}</Heading>
        <Text color={'gray.500'} fontSize={'lg'}>
            {desc}
        </Text>
        <Stack
            spacing={4}
            divider={
                <StackDivider
                    borderColor={useColorModeValue('gray.100', 'gray.700')}
                />
            }>
            {claims.map((claim, i) => {

                return <Feature
                    iconBg={colors[i][0]}
                    text={claim}
                />
            })}
        </Stack>
    </Stack>
}

const RowImg = (img) => {
    return <Flex>
        <Image
            rounded={'md'}
            alt={'feature image'}
            src={
                img
            }
            objectFit={'cover'}
        />
    </Flex>
}
export const RowDerecha = (props) => {
    return <SimpleGrid alignItems={'center'} columns={{ base: 1, md: 2 }} spacing={10} mb='24'>
        {RowText(props)}
        {RowImg(props.img)}
    </SimpleGrid>
}

const RowIzq = (props) => {

    return <SimpleGrid alignItems={'center'}columns={{ base: 1, md: 2 }} spacing={10} mb='24'>
        {RowImg(props.img)}
        {RowText(props)}
    </SimpleGrid>
}
export default function ProyectosHome() {
    return (
        <Container maxW={'5xl'} py={12} mb='8'>
            <Box py='8' mt='4' mb={'8'} textAlign={'center'}>

                <Heading
                    color={'gray.500'}
                    mb={5}
                    fontSize={{ base: '3xl', md: '5xl' }}>
                    Portafolio de Proyectos
                </Heading>
            </Box>

            {RowDerecha(Proyectos.TorreSenderos)}
            {RowIzq(Proyectos.Parq)}
            {RowDerecha(Proyectos.ParqLucia)}

            {RowIzq(Proyectos.Habanero)}
        </Container>
    )
}
