import { Box, Text } from "@chakra-ui/react";
import Masonry from "../components/masonry";
import ProyectosData from '../components/utils/proyectosData'

function Proyectos() {
    return (
        <Masonry data={ProyectosData}/>
    );
}

export default Proyectos;
