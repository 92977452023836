import { Box, Container, Heading, HStack, Stack, StackDivider, Text } from "@chakra-ui/react"

function Feature({ title, desc, ...rest }) {
    return (
        <Box
            p={5}
            shadow='md'
            borderWidth='1px'
            flex='1'
            borderRadius='md'
            {...rest}
        >
            <Heading fontSize='xl'>{title}</Heading>
            <Text mt={4}>{desc}</Text>
        </Box>
    )
}

export default function StackEx() {
    return (
        <Stack direction={{md:'row', base:'column'}} spacing={6} alignItems='center' justify='center' divider={<StackDivider />} py='3' my={'4'}>
            <Box maxW={{base:'100%',md:'25%'}} p={{base:'4', md:0}} textAlign='center' >
                <Text
                    fontFamily={'heading'}
                    fontSize={{base:'lg',lg:'xl'}}
                    color={'gray.700'}
                    my={2}>
                    Asesoría en conceptualización y Desarrollo de proyectos inmobiliarios.
                </Text>
                <Text fontSize={'md'} color={'gray.400'}>
                    Desde evaluación de terreno hasta escrituración de las unidades.
                </Text>
            </Box>
            <Box maxW={{base:'100%',md:'25%'}} p={{base:'4', md:0}} textAlign='center' >
                <Text
                    fontFamily={'heading'}
                    fontSize={{base:'lg',lg:'xl'}}
                    color={'gray.700'}
                    my={2}>
                    Desarrollo de proyectos propios
                </Text>
                <Text fontSize={'md'} color={'gray.400'}>
                    con presencia en Monterrey, Playa del Carmen y Torreón
                </Text>
            </Box>
            <Box maxW={{base:'100%',md:'25%'}} p={{base:'4', md:0}} textAlign='center' >
                <Text
                    fontFamily={'heading'}
                    fontSize={{base:'lg',lg:'xl'}}
                    color={'gray.700'}
                    my={2}>
                    Asesoría en comercialización de propiedades
                </Text>
            </Box>

            {/* <Feature
                title='Plan Money'
                desc='The future can be even brighter but a goal without a plan is just a wish'
            />
            <StackDivider></StackDivider>

            <Feature
                title='Save Money'
                desc='You deserve good things. With a whooping 10-15% interest rate per annum, grow your savings.'
            />
            <StackDivider></StackDivider>
            <Feature
                title='Save Money'
                desc='You deserve good things. With a whooping 10-15% interest rate per annum, grow your savings.'
            /> */}
        </Stack>


    )
}
