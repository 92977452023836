import Contact from "../components/contactForm";
import SplitWithImage from "../components/contents/contentOne";
import SplitWithImageRev from "../components/contents/contentOneRev";
import GridListWithHeading from "../components/contents/contentThree";
import StatsGridWithImage from "../components/contents/contentTwo";
import Carousel from "../components/heroCarousel";
import StackEx from "../components/infoStack";
import Masonry from "../components/masonry";
import ModalComp from "../components/modal";
import ProyectosHome from "../components/ProyectosHome";
import Hero from '../components/Hero'


function Home() {
    return (
        <>
            <Hero/>
            {/*<Carousel />*/}
            <StackEx />
            <ProyectosHome />
            {/* <SplitWithImage /> */}
            {/* <SplitWithImageRev /> */}
            {/* <StatsGridWithImage />
            <GridListWithHeading />
            <Masonry />
            <Contact /> */}
        </>
    );
}

export default Home;
