import { Box, Container, Flex, Heading, Image, SimpleGrid, Stack, StackDivider, Text, useColorModeValue } from "@chakra-ui/react";
import SplitWithImage, { Feature } from "../components/contents/contentOne";
import StatsGridWithImage from "../components/contents/contentTwo";
import HistoriaDos from "../components/contents/historiaDos";
import heroImg from '../assets/hero_1.jpeg'

function Historia() {
    return (
        <>
            <Box minH={'60px'} />
            <Container maxW={'5xl'} py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Stack spacing={4}>
                        {/* <Text
                            textTransform={'uppercase'}
                            color={'blue.400'}
                            fontWeight={600}
                            fontSize={'sm'}
                            bg={useColorModeValue('blue.50', 'blue.900')}
                            p={2}
                            alignSelf={'flex-start'}
                            rounded={'md'}>
                            Nosotros
                        </Text> */}
                        <Heading>Nosotros</Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                           Desarrolladora inmobiliaria fundada en 2017 con presencia en Nuevo Leon, Coauila y Quintana Roo. 
                        </Text>
                        <Stack
                            spacing={4}
                            divider={
                                <StackDivider
                                    borderColor={useColorModeValue('gray.100', 'gray.700')}
                                />
                            }>
                            <Feature
                                /* icon={
                                    <Icon as={} color={'yellow.500'} w={5} h={5} /> 
                                } */
                                iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                                text={'Planeación y Desarrollo'}
                            />
                            <Feature
                                /* icon={<Icon as={} color={'green.500'} w={5} h={5} />} */
                                iconBg={useColorModeValue('green.100', 'green.900')}
                                text={'Planeación Económica'}
                            />
                            <Feature
                                /* icon={
                                    <Icon as={} color={'purple.500'} w={5} h={5} /> 
                                } */
                                iconBg={useColorModeValue('purple.100', 'purple.900')}
                                text={'Análisis de Mercado'}
                            />
                        </Stack>
                    </Stack>
                    <Flex>
                        <Image
                            rounded={'md'}
                            alt={'feature image'}
                            src={
                            heroImg
                            }
                            objectFit={'cover'}
                        />
                    </Flex>
                </SimpleGrid>
            </Container>

            <StatsGridWithImage />
            <HistoriaDos />


        </>

    );
}

export default Historia;
