import React, { createContext } from "react";
import PortalModal from "../PortalModal";
import useModal from "./useModal";


let ModalContext = createContext()
let { Provider } = ModalContext

let ModalProvider = ({ children }) => {
    let { modal, handleModal, modalContent } = useModal();
    return (
        <Provider value={{ modal, handleModal, modalContent }}>
            <PortalModal />
            {children}
        </Provider>
    );
};

export { ModalContext, ModalProvider };