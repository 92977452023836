import { Box, Text } from "@chakra-ui/react";
import Contact from "../components/contactForm";

function Contacto() {
    return (
        <>
            <Box minH={'60px'} />
            <Contact />
        </>

    );
}

export default Contacto;