import { Box, Container, Flex, Heading, IconButton, SimpleGrid, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import hero_1 from '../assets/fachada_b.jpeg'
import { useInView } from 'react-intersection-observer';
import { useRef } from 'react';
//import useIntersectionObserver from './utils/useIntersection'

export default function Hero() {


    return <Flex
        //position={'relative'}
        height={'100vh'}
        width={'full'}
        //overflow={'hidden'}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        backgroundImage={`url(${hero_1})`} 
        id={'hero'}
    >
        

        <Container maxW={'7xl'} >
            <Stack direction={'row'} height={'full'}>
                <Stack
                    flex={1}
                    color={'gray.400'}
                    justify={{ base: 'center' }}
                    py={{ base: 4, md: 20, xl: 60 }}>
                    <Box mb={{ base: 8, md: 20 }}>
                        <Text
                            fontFamily={'heading'}
                            fontWeight={500}
                            //textTransform={'uppercase'}
                            mb={1}
                            fontSize={{ base: '3xl', md: '5xl', }}
                            color={'white'}
                            textAlign='end'
                        >
                            Creamos
                        </Text>
                        <Heading
                            color={'white'}
                            mb={5}
                            fontSize={{ base: '5xl', md: '7xl' }}
                            textAlign='end'
                        >
                            Comunidades
                        </Heading>

                    </Box>

                </Stack>
            </Stack>
        </Container>
    </Flex>

}
 /*                       <Text fontSize={'xl'} color={'white'}>
                           Nuestro objetivo principal es brindar espacios 
                           de calidad y confort en vivienda vertical.
                       </Text>*/
