import React, { useContext } from "react";
import { ChakraProvider, Box, Image, Link, Container, Text } from "@chakra-ui/react";

import { Link as ReachLink } from "react-router-dom"
import { ModalContext } from "./utils/modalContext";

let srces = []
export let getImageById = (id) => srces[id]
export default function Masonry({ data }) {


    const { handleModal } = useContext(ModalContext)
    return (
        <Container maxW={'7xl'} py='24' mb={'8'}>
            <Box
                padding={1}
                w="100%"
                maxW="900px"
                mx="auto"
                bg="white"
                sx={{ columnCount: [1, 2 ], columnGap: "8px" }}
            >
                {
                    Object.keys(data).map((key, i) => (
                        <Box as="button" onClick={() => handleModal({...data[key]})} backgroundColor='red' w="100%"
                            borderRadius="sm"
                            mb={2} d="inline-block">
                            <Image
                                key={i}
                                w="100%"
                                /* borderRadius="sm" */
                                /* mb={2} */
                                /* d="inline-block" */
                                src={data[key].img}
                                alt="Alt"
                            />
                        </Box>
                    ))
                }
            </Box>
        </Container>
    );
}
