import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import WithSubnavigation from './components/navBar';
import Carousel from './components/heroCarousel';
import StackEx from './components/infoStack';
import Footer from './components/footer';
import SplitWithImage from './components/contents/contentOne';
import StatsGridWithImage from './components/contents/contentTwo';
import GridListWithHeading from './components/contents/contentThree';
import Masonry from './components/masonry';
import ModalComp from './components/modal';
import Contact from './components/contactForm';
import SplitWithImageRev from './components/contents/contentOneRev';
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from './components/contents/layout';
import Home from './pages/Home';
import Historia from './pages/Historia';
import Proyectos from './pages/Proyectos';
import Contacto from './pages/Contacto';
import { ModalProvider } from './components/utils/modalContext';


function App() {
  let location = useLocation();
  let state = location.state
  return (
    <ChakraProvider theme={theme}>
      <ModalProvider>
        <Routes >
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='nosotros' element={<Historia />} />
            <Route path='proyectos' element={<Proyectos />} />
            <Route path='contacto' element={<Contacto />} />
          </Route>
        </Routes>
      </ModalProvider>



    </ChakraProvider>
  );
}

export default App;
