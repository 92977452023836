
import { RefObject, useEffect, useRef, useState } from 'react'


const useObserver = options => {
    const [elements, setElements] = useState([])
    const [entries, setEntries] = useState([])

    const observer = useRef(null)

    const observerOptions = options || {}

    useEffect(() => {
        if (elements.length) {
            console.log('hook thrown')
            observer= new IntersectionObserver(observedEntries => {
                console.log('set entries')
                setEntries(observedEntries)
            }, observerOptions)
            elements.forEach(element => { 
                console.log(element) 
                observer.observe(element) })
        }
        return () => {
            if (observer.current) {
                observer.current.disconect()
            }
        }
    }, [elements, observerOptions])

    return [observer.current, setElements, entries]
}

export default useObserver
