import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    IconButton,
    Button,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    Image,
} from '@chakra-ui/react';
import {
    MdContactPhone,
    MdEmail,
    MdLocationOn,
    MdFace,
    MdExpandMore,
} from 'react-icons/md';
import img from '../assets/fachada_05.jpeg'
/* import { BsGithub, BsDiscord, BsPerson } from 'react-icons/bs'; */

export default function Contact() {
    return (
        <Container bg="white" maxW="full" mt={0} p={{ base: '4' }} alignContent='center' overflow="hidden">
            <Flex

                bg="#02054B"
                color="white"
                borderRadius="lg"
                m={{ sm: 4, md: 16, lg: 10 }}
                p={{ sm: 5, md: 5, lg: 16 }}
                //            width={'full'}
                //width={'full'}
                minH={'lg'}
                //overflow={'hidden'}
                backgroundSize={'contain'}
                backgroundPosition={'left'}
                backgroundImage={`url(${img})`}
            >
                <Flex p={4} width={'full'}
                    justifyContent={{base:'center', md:'flex-end'}}
                >

                    <Flex alignItems={'center'} p={'2'} backgroundColor={'gray.100'} flexDirection={{base:'column', md:'row'}} width={{base:'full',lg:'50%', '2xl':'lg'}}>
                        <Box p={'5'}>
                            <Heading color="gray.700">Contacto</Heading>
                            <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                                Ponte en contacto con nosotros:
                            </Text>
                            <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                                <VStack pl={0} spacing={3} alignItems="flex-start">
                                    <Text color="gray.500"
                                    >cel. 818011 1060
                                    </Text>
                                    <Text color="gray.500">
                                        hola@bethesda.com.mx
                                    </Text>
                                    <Text color="gray.500">
                                        Monterrey, Nuevo León.
                                    </Text>
                                </VStack>
                            </Box>
                            <HStack
                                mt={{ lg: 10, md: 10 }}
                                spacing={5}
                                px={5}
                                alignItems="flex-start">
                            </HStack>
                        </Box>
                        <Box bg="white" borderRadius="lg">
                            <Box m={8} color="#0B0E3F">
                                <VStack spacing={5}>
                                    <FormControl id="name">
                                        <FormLabel>Nombre:</FormLabel>
                                        <InputGroup borderColor="#E0E1E7">
                                            <InputLeftElement
                                                pointerEvents="none"
                                            /* children={<BsPerson color="gray.800" />} */
                                            />
                                            <Input type="text" size="md" />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl id="name">
                                        <FormLabel>Correo:</FormLabel>
                                        <InputGroup borderColor="#E0E1E7">
                                            <InputLeftElement
                                                pointerEvents="none"
                                            /* children={<MdOutlineEmail color="gray.800" />} */
                                            />
                                            <Input type="text" size="md" />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl id="name">
                                        <FormLabel>Mensaje:</FormLabel>
                                        <Textarea
                                            borderColor="gray.300"
                                            _hover={{
                                                borderRadius: 'gray.300',
                                            }}
                                            placeholder="mensaje"
                                        />
                                    </FormControl>
                                    <FormControl id="name" float="right">
                                        <Button
                                            variant="solid"
                                            bg="#0D74FF"
                                            color="white"
                                            _hover={{}}>
                                            Enviar
                                        </Button>
                                    </FormControl>
                                </VStack>
                            </Box>
                        </Box>
                    </Flex>
                </Flex>

            </Flex>
        </Container>
    );
}
