import { Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import React, { useContext } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getImageById } from "./masonry";
import { RowDerecha } from "./ProyectosHome";
import { ModalContext } from "./utils/modalContext";
import useModal from './utils/useModal'

export default function ModalComp({ handleModal }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [scrollBehavior, setScrollBehavior] = React.useState('inside')
    const {modalContent} = useContext(ModalContext)

    const btnRef = React.useRef()
    return (
        <Modal
            onClose={handleModal}
            finalFocusRef={btnRef}
            isOpen={true}
            scrollBehavior={'inside'}
            size={'2xl'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody>
                    {RowDerecha(modalContent)}
                </ModalBody>
                <ModalFooter/>
            </ModalContent>
        </Modal>
    )
}
