import React, { useContext } from "react";
import ReactDOM from "react-dom";
import ModalComp from "./modal";
import { ModalContext } from "./utils/modalContext";


const PortalModal = () => {
    let { modalContent, handleModal, modal } = useContext(ModalContext);
    if (modal) {
        return ReactDOM.createPortal(
            <ModalComp handleModal={handleModal} />,
            document.querySelector("#modal-root")
        );
    } else return null;
};

export default PortalModal;