import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Image,
    useBreakpoint,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,

} from '@chakra-ui/icons';
import { FaFacebookF, FaGratipay, FaInstagram } from 'react-icons/fa'
import { Link as ReachLink, useLocation } from "react-router-dom"
import { useEffect, useState } from 'react';
import logoBlanco from '../assets/logo_blanco_ajustado.png'
import useObserver from './utils/useIntersection';


//width={700}
//height={475}
const Logo = () => <Image
    layout="responsive"
    width={180}
    alt="Parq"
    src={logoBlanco}
/>



export default function WithSubnavigation() {
    const { isOpen, onToggle } = useDisclosure();
    const [doBackground, setDoBackground] = useState(true)
    const [changeNavBar, setChangeNavBarColor] = useState(false)
    const [firstRun, setFirstRun] = useState(true)
    let location = useLocation()
    const breakpoint = useBreakpoint({ ssr: false })
    const [observer, setElements, entries] = useObserver({ threshold: 0.75, root: null })

    const hero = document.getElementById('hero')
    //const header = document.querySelector("header");
    const sectionOne = document.getElementById("hero");

    const sectionOneOptions = {
        threshold: 0.25
    };


    useEffect(() => {
        const sectionOneObserver = new IntersectionObserver(function(
            entries,
            sectionOneObserver
        ) {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    setDoBackground(false)
                } else {
                    setDoBackground(true)

                }
            });
        }, sectionOneOptions);
        if (sectionOne) {
            sectionOneObserver.observe(sectionOne);
        }

    }, [sectionOne])
    //sectionOneObserver.observe(sectionOne);




    //    window.addEventListener('scroll', changeNavbarColor);
    useEffect(() => {
        if (location.pathname == '/') {
            setDoBackground(true)
            return
        }
        setDoBackground(false)
        if (isOpen) {
            onToggle()
        }
        return
    }, [location.pathname])
    return (
        <Box as="header" position="fixed" zIndex={'100'} backgroundColor={doBackground ? 'rgba(255,255, 255, 0.1)' : 'gray.700'} backdropFilter="saturate(180 %) blur(7px)" w={'100%'} >
            < Flex
                bg={useColorModeValue('transparent', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }
                }
                px={{ base: 4 }}
                borderBottom={1}
                /* borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')} */
                align={'center'}
                maxW='container.xl'
                justify={'center'}
                marginLeft='auto'
                marginRight='auto'

            >
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: 'end', md: 'space-between' }} align='center' /* border='1px' borderColor='red.200' */>
                    <Link as={ReachLink} to="/" >
                        <Logo />
                    </Link>


                    <Flex display={{ base: 'none', md: 'flex' }} /* border='1px' borderColor='red.200' */>
                        <DesktopNav />
                    </Flex>
                    <Stack direction={'row'} spacing={4} /* border='1px' borderColor='red.200' */ display={{ base: 'none', md: 'flex' }}>
                        <Icon color={'white'} w={5} h={5} as={FaFacebookF} />
                        <Icon color={'white'} w={5} h={5} as={FaInstagram} />
                    </Stack>

                </Flex>


            </Flex >

            <Collapse in={isOpen} animateOpacity>
                <MobileNav onClick={onToggle} />
            </Collapse>
        </Box >
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('white', 'gray.200');
    const linkHoverColor = useColorModeValue('white', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'row'} spacing={8}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>

                    <Link as={ReachLink} to={navItem.path || '/about'} p={1}
                        href={navItem.href ?? '#'}
                        fontSize={'sm'}
                        fontWeight={500}
                        color={linkColor}
                        _hover={{
                            textDecoration: 'none',
                            color: linkHoverColor,
                            borderBottom: 'solid',
                            borderColor: 'white',
                            paddingBottom: 1
                        }}>
                        {navItem.label}
                    </Link>


                </Box>
            ))
            }

        </Stack >
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'pink.400' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = (onToggle) => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href, path }) => {
    //   const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} >
            <Link as={ReachLink} to={path || '/about'} >
                <Flex py={2}
                    href={href ?? '#'}
                    justify={'space-between'}
                    align={'center'}
                    _hover={{
                        textDecoration: 'none',
                    }}
                >
                    <Text
                        fontWeight={600}
                        color={useColorModeValue('gray.600', 'gray.200')}>
                        {label}
                    </Text>
                </Flex>
            </Link>

        </Stack>
    );
};

//{
//    children && (
//        <Icon
//            as={ChevronDownIcon}
//            transition={'all .25s ease-in-out'}
//            transform={isOpen ? 'rotate(180deg)' : ''}
//            w={6}
//            h={6}
//        />
//    )
//}

//<Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
//    <Stack
//        mt={2}
//        pl={4}
//        borderLeft={1}
//        borderStyle={'solid'}
//        borderColor={useColorModeValue('gray.200', 'gray.700')}
//        align={'start'}>
//        {children &&
//            children.map((child) => (
//                <Link key={child.label} py={2} href={child.href}>
//                    {child.label}
//                </Link>
//            ))}
//    </Stack>
//</Collapse>

const NAV_ITEMS = [
    /* {
        label: 'About',
        path: '/about',
    }, */
    {
        label: 'Proyectos',
        path: '/proyectos',
    },
    {
        label: 'Contacto',
        path: '/contacto',
    },
];
