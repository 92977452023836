
import torre_senderos from '../../assets/fachada_08.jpeg'
import parq from '../../assets/fachada_03.jpeg'
import parqLucia from '../../assets/fachada_04.jpeg'
import habanero from '../../assets/fachada_05.jpeg'
const TorreSenderos = {

    fecha: '2018 - 2022', proyecto: 'Torre Senderos', img: torre_senderos, desc: 'Desarrollo vertical de departamentos construidos en un terreno con superficie de 2,500 M2.', claims: ['Torre 1 – 22 departamentos en 6 niveles.', 'Torre 2 - 17 departamentos en 5 niveles.  ',
        'Ubicación: Torreón, Coahuila.'

    ]
}

const Parq = {


    fecha: '2022 – En construcción', proyecto: 'Parq Torreón', img: parq, desc: 'Desarrollo vertical de departamentos construidos en un terreno con superficie de 2,500 M2.', claims: ['2 torres de 29 departamentos en 6 niveles cada una. ',
        'Ubicación: Torreón, Coahuila.'

    ]
}
const ParqLucia = {


    fecha: 'En proceso', proyecto: 'Parq Santa Lucía', img: parqLucia, desc: 'Desarrollo vertical de 120 departamentos construidos en un terreno con superficie de 1,354 M2.', claims: ['Edificio de uso mixto: vivienda vertical, comercio y oficinas','Total de construcción de 12,000 M2',
        'Ubicación: Monterrey, Nuevo León.'

    ]
}
const Habanero = {


    fecha: 'En proceso', proyecto: 'Casa Habanero', img: habanero, desc: 'Desarrollo vertical de 26 departamentos construidos en un terreno con superficie de 220 M2.', claims: ['Ubicación: Playa del Carmen, Quintana Roo.',

    ]
}

const Proyectos = {
TorreSenderos, Parq, ParqLucia, Habanero
}
export default Proyectos
